@import './../../../assets/variables.scss';

.h3 {
    color: $black;
    font-size: 4.8rem;
    line-height: 4.3rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: -0.07em;

    @media screen and (max-width: 768px) {
        font-size: 3.2rem;
        line-height: 2.9rem;
    }

    @media screen and (max-height: 500px) {
        font-size: 3.2rem;
        line-height: 2.9rem;
    }

    @media screen and (max-height: 400px) {
        font-size: 2.4rem;
        line-height: 2.3rem;
    }
}