@import './../../../assets/variables.scss';

.gold {
    color: $gold1;
    font-size: 1.8rem;
    line-height: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.06em;

    @media screen and (max-width: 768px) {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }

    @media screen and (max-height: 500px) {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }

    @media screen and (max-height: 550px) {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }
}