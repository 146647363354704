@import './../../assets/variables.scss';

.input {
    min-height: 5.1rem;
    width: 100%;
    border-bottom: 1px solid $black;
    padding: 0.5rem 0;
    color: $black;
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 700;
    background-color: transparent;

    @media screen and (max-height: 440px) {
        min-height: 3.1rem;
    }

    @media screen and (max-height: 340px) {
        min-height: 2.1rem;
    }

    &::placeholder {
        color: rgba($color: $black, $alpha: 0.3);
        text-transform: uppercase;
    }

    &_error {
        border-color: $red;
    }

    &.disabled {
        background-color: transparent;
        border-color: transparent;
        padding-left: 0;
        padding-right: 0;

        .input_error {
            display: none;
        }
    }
}

.textarea {
    flex-grow: 1;
    resize: none;
}
