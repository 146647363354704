@import "./../../assets/variables.scss";

.page404 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3.2rem;
    flex-grow: 1;
    background-color: $gray1;
    text-align: center;

    &::before, &::after {
        content: "";
        display: block;
        flex-grow: 1;
    }
}