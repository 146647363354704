@import './../../assets/variables.scss';

.footer {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    min-height: 9.6rem;
    position: relative;
    z-index: 1;

    // @media screen and (max-height: 925px) and (max-width: 1450px) {
    //     padding: 0.5rem 0;
    //     min-height: 7rem;
    // }

    @media screen and (max-height: 650px) {
        padding: 0.5rem 0;
        min-height: 5rem;
    }

    @media screen and (max-width: 768px) {
        padding: 0.5rem 0;
        min-height: 5rem;
    }

    &_slide {
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__menu {
        display: flex;
        align-items: center;
        column-gap: 3.3rem;
        flex-shrink: 0;

        &_long {
            @media screen and (max-width: 768px) {
                flex-grow: 1;
                justify-content: space-between;
            }
        }
    }

    &__mail {
        display: flex;
        flex-shrink: 0;
    }

    &__langs {
        display: flex;
        flex-shrink: 0;
        column-gap: 0.8rem;
    }

    &__lang {
        color: rgba($color: #231f20, $alpha: 0.2);
        font-size: 1.8rem;
        line-height: 1.8rem;
        font-weight: 700;
        letter-spacing: 0.06em;
        position: relative;
        white-space: nowrap;
        text-transform: uppercase;
        background-color: transparent;
        border: none;

        @media screen and (max-height: 650px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }

        .footer__lang__hover {
            color: $gold1;
            position: absolute;
            left: 0;
            pointer-events: none;
            width: 0;
            overflow: hidden;
            transition: width 0.5s;
            white-space: nowrap;
        }

        @media screen and (hover) {
            cursor: pointer;

            &:hover {
                .footer__lang__hover {
                    width: 100%;
                }
            }
        }

        &.active .footer__lang__hover {
            width: 100%;
        }
    }
}
