@import './../../assets/variables.scss';

.logo {
    display: block;
    flex-shrink: 0;
    width: 17.6rem;
    height: 13rem;

    &__path1 {
        fill: $black1;
    }

    &__path2, &__path3 {
        fill: $gold1;
    }

    @media screen and (hover) {
        cursor: pointer;

        .logo__path1, .logo__path2, .logo__path3 {
            transition: fill 0.5s;
        }

        &:hover {
            .logo__path1 {
                fill: $gold1;
            }

            .logo__path2, .logo__path3 {
                fill: $black1;
            }
        }
    }

    @media screen and (max-width: 768px) {
        width: 13.4rem;
        height: 10rem;
    }
}

.logoHeader {
    display: block;
    flex-shrink: 0;
    width: 4.9rem;
    height: 5.4rem;

    path {
        fill: $gold1;
    }

    @media screen and (hover) {
        cursor: pointer;

        path {
            transition: fill 0.5s;
        }

        &:hover {
            path {
                fill: $black1;
            }
        }
    }

    @media screen and (max-width: 768px) {
        width: 49px;
        height: 54px;
    }
}
