@import './../../../assets/variables.scss';

.production {
    min-width: 100dvw;
    flex-shrink: 0;
    height: 100%;
    position: relative;
    display: flex;

    &__content {
        height: 100%;
        display: grid;
        grid-auto-flow: column;
        column-gap: 17.6rem;
        grid-template-rows: 100%;
        @media screen and (max-height: 925px) and (max-width: 1450px) {
            column-gap: 10rem;
        }

        @media screen and (max-width: 768px) {
            column-gap: 8rem;
        }
    }

    &__titleWrapper {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;

        &::before {
            content: '';
            display: block;
            flex-grow: 0.2;
        }

        &::after {
            content: '';
            display: block;
            flex-grow: 0.8;
        }
    }

    &__title {
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
    }

    &__scroll {
        width: fit-content;
        max-width: 100%;
        display: flex;
        column-gap: 1.6rem;
        align-items: center;
    }

    &__firstPictureWrapper {
        flex-shrink: 0;
        height: 100%;
        position: relative;
    }

    &__firstPic {
        display: block;
        height: 100%;
        min-height: 0;
        width: auto;
        object-fit: cover;

        @media screen and (max-height: 925px) and (max-width: 1450px) {
            max-width: 63rem;
        }
    }

    &__itemsWrapper {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__items {
        flex-shrink: 0;
        display: flex;
        column-gap: 17.6rem;
        padding: 2rem 0;

        @media screen and (max-height: 925px) and (max-width: 1450px) {
            column-gap: 8rem;
        }

        @media screen and (max-height: 850px) {
            padding: 0;
        }
    }

    &__item {
        width: 43.2rem;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 2.4rem;
        text-align: center;

        @media screen and (max-height: 925px) and (max-width: 1450px) {
            width: 44rem;
        }

        @media screen and (max-height: 875px) {
            width: 55rem;
            row-gap: 1.2rem;
        }

        @media screen and (max-height: 550px) {
            width: 65rem;
        }

        @media screen and (max-height: 450px) {
            width: 35rem;
        }
    }

    &__picture {
        width: 100%;
        position: relative;
    }

    &__bg {
        width: 100%;
        height: 100%;
        & > div {
            position: relative;
            width: 100%;
            height: 100%;
        }

        & > div > div {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    &__itemPic {
        display: block;
        width: 100%;
        object-fit: contain;

        @media screen and (max-height: 950px) {
            max-height: 28rem;
        }

        @media screen and (max-height: 700px) {
            max-height: 20rem;
        }

        @media screen and (max-height: 550px) {
            max-height: 15rem;
        }

        @media screen and (max-height: 450px) {
            max-height: 10rem;
        }

        @media screen and (max-height: 350px) {
            max-height: 9rem;
        }
    }

    &__itemName {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    &__itemNumber {
        color: rgba($color: $black, $alpha: 0.1);
        font-size: 12.8rem;
        line-height: 11.5rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: -0.04em;

        @media screen and (max-width: 1400px) {
            font-size: 8rem;
            line-height: 8rem;
        }

        @media screen and (max-width: 900px) {
            font-size: 6rem;
            line-height: 6rem;
        }

        @media screen and (max-width: 768px) {
            font-size: 5rem;
            line-height: 5rem;
        }

        @media screen and (max-width: 550px) {
            font-size: 5rem;
            line-height: 5rem;
        }

        @media screen and (max-height: 700px) and (min-width: 950px) {
            font-size: 8rem;
            line-height: 8rem;
        }

        @media screen and (max-height: 480px) {
            font-size: 5rem;
            line-height: 5rem;
        }
    }

    &__itemNameWrapper {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}

.vproduction {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;

    &__firstPic {
        width: 100%;
        display: block;
        margin-bottom: 8.8rem;

        img {
            width: 100%;
            display: block;
        }
    }

    &__title {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
    }

    &__swiper {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
    }

    &__item {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 3.2rem;
        text-align: center;
    }

    &__picture {
        width: 100%;
        position: relative;
    }

    &__bg {
        width: 100%;
        height: 100%;
    }

    &__itemPic {
        display: block;
        width: 100%;
        object-fit: contain;
    }

    &__itemName {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    &__itemNumber {
        color: rgba($color: $black, $alpha: 0.1);
        font-size: 12.8rem;
        line-height: 11.5rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: -0.04em;

        @media screen and (max-width: 1400px) {
            font-size: 8rem;
            line-height: 8rem;
        }

        @media screen and (max-width: 900px) {
            font-size: 6rem;
            line-height: 6rem;
        }

        @media screen and (max-width: 768px) {
            font-size: 5rem;
            line-height: 5rem;
        }

        @media screen and (max-width: 550px) {
            font-size: 5rem;
            line-height: 5rem;
        }
    }

    &__itemNameWrapper {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}
