@import './../../../assets/variables.scss';

.checkbox {
    width: fit-content;
    max-width: 100%;
    padding: 1.5rem 2.5rem;
    border: 1px solid $white;
    border-radius: 0.2rem;
    background-color: $white;
    white-space: nowrap;
    font-family: $din-family;
    color: $gray;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: background-color 0.5s, color 0.5s, border-color 0.5s;

    @media screen and (hover) {
        cursor: pointer;

        &:hover {
            border-color: $red;
            color: $red;
        }
    }

    @media screen and (max-width: 768px) {
        padding: 1.2rem 2.5rem;
        font-size: 1.4rem;
        line-height: 1.9rem;
    }

    &.active {
        background-color: $red;
        border-color: $red;
        color: $white1;
    }
}