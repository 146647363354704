@import './../../../assets/variables.scss';

.home {
    width: 100dvw;
    height: 100%;
    position: relative;

    &__bg {
        position: absolute;
        inset: 0;
        left: 4rem;
        right: 4rem;
        background: url(../../../assets/svg/home_bg.svg) center no-repeat;
        background-size: contain;
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width: 1500px) and (min-width: 1200px) {
            left: -2rem;
            right: 1.4rem;
        }

        @media screen and (max-width: 768px) {
            left: 1.6rem;
            right: 1.6rem;
        }

        @media screen and (max-height: 550px) {
            left: 1.6rem;
            right: 1.6rem;
        }
    }

    &__bgHover {
        width: 100%;
        height: 100%;
        background-color: $white;
        transition: width 4s;

        &_hide {
            width: 0;
        }
    }

    &__content {
        height: 100%;
        display: flex;
        column-gap: 2.4rem;
        position: relative;
        z-index: 1;

        @media screen and (max-width: 430px) and (max-height: 350px) {
            overflow: hidden;
        }
    }

    &__bottleWrapper {
        height: 100%;
        position: relative;
        max-width: calc(50% - 1.2rem);

        @media screen and (max-width: 768px) {
            margin-right: -5rem;
        }

        @media screen and (max-width: 430px) and (max-height: 350px) {
            margin-left: -5rem;
        }
    }

    &__bottle {
        height: 100%;
        width: auto;
        max-width: 100%;
        display: block;
        object-fit: contain;
        object-position: center;
    }

    &__data {
        width: 73.6rem;
        max-width: calc(50% - 1.2rem);
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        position: relative;
        z-index: 1;

        @media screen and (max-width: 768px) {
            max-width: calc(50% + 3.8rem);
        }

        @media screen and (max-width: 430px) and (max-height: 350px) {
            max-width: calc(50% + 8.8rem);
        }
    }

    &__scroll {
        width: 100%;
        border-bottom: 1px solid $gold1;
        padding-bottom: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 1rem;
    }

    &__texts {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &__textsTop {
        flex-grow: 0.48;
        min-height: 1rem;
    }

    &__textsMiddle {
        flex-grow: 0.14;
        min-height: 1rem;
    }

    &__textsBottom {
        flex-grow: 0.38;
        min-height: 1rem;
    }

    &__title {
        max-width: 63rem;

        @media screen and (max-height: 925px) and (max-width: 1450px) {
            max-width: 51rem;
        }
    }

    &__advantages {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
    }

    &__advantage {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 1.6rem;

        p {
            font-weight: 400 !important;
        }
    }

    &__advantageIco {
        width: 4.6rem;
        height: 4.6rem;
        flex-shrink: 0;

        @media screen and (max-height: 550px) {
            width: 3rem;
            height: 3rem;
        }

        @media screen and (max-height: 450px) {
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}

.vhome {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;

    &__bottleWrapper {
        width: 100%;
        display: flex;
        position: relative;
        overflow: hidden;
    }

    &__bottle {
        width: 100%;
        position: relative;
        z-index: 1;
        max-width: 100%;
        min-height: 480px;
        object-fit: cover;
        object-position: center;
        img {
            display: block;
            width: 100%;
        }
    }

    &__bg {
        position: absolute;
        inset: 0;
        background: url(../../../assets/svg/home_bg.svg) 50% 15% no-repeat;
        background-size: contain;
        display: flex;
        justify-content: flex-end;
    }

    &__bgHover {
        width: 100%;
        height: 100%;
        background-color: $white;
        transition: width 3s;

        &_hide {
            width: 0;
        }
    }

    &__texts {
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
    }

    &__advantages {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
    }

    &__advantage {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 1.6rem;

        p {
            font-weight: 400 !important;
        }
    }

    &__advantageIco {
        width: 4.6rem;
        height: 4.6rem;
        flex-shrink: 0;

        @media screen and (max-height: 550px) {
            width: 3.2rem;
            height: 3.2rem;
        }
    }
}
