@import './../../../assets/variables.scss';

.button {
    min-height: 5.1rem;
    width: fit-content;
    max-width: 100%;
    padding: 1.6rem 3.2rem;
    border: 2px solid $gold1;
    background-color: transparent;
    color: $gold1;
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;

    @media screen and (hover) {
        cursor: pointer;
        transition: background-color 0.5s, color 0.5s;

        .button__ico path {
            transition: fill 0.5s;
        }

        &:hover {
            background-color: $gold1;
            color: $white;

            .button__ico path {
                fill: $white;
            }
        }
    }

    @media screen and (max-width: 768px) {
        min-height: 4.6rem;
        font-size: 1.4rem;
        line-height: 1.4rem;
    }

    @media screen and (max-height: 500px) {
        min-height: 4.6rem;
        font-size: 1.4rem;
        line-height: 1.4rem;
    }

    @media screen and (max-height: 400px) {
        min-height: 3.2rem;
        font-size: 1.2rem;
        line-height: 1.2rem;
        padding: 0.5rem 2.4rem;
    }

    &__ico {
        display: block;
        flex-shrink: 0;
        width: 2.4rem;
        height: 2.4rem;

        path {
            fill: $gold1;
        }
    }
}

.icobutton {
    height: 4rem;
    width: 4rem;
    padding: 0.5rem;
    border: 2px dashed $gold1;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    &__ico {
        path {
            fill: $gold1;
        }
    }

    @media screen and (hover) {
        cursor: pointer;
        transition: background-color 0.5s;

        .icobutton__ico path {
            transition: fill 0.5s;
        }

        &:hover {
            background-color: $gold1;
            border-style: solid;

            .button__ico path {
                fill: $white;
            }
        }
    }
}