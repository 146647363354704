@import './../../../assets/variables.scss';

.text {
    color: $black;
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: 300;
    letter-spacing: 0.02em;

    &_small {
        font-size: 1.8rem;
        line-height: 2.1rem;
    }

    @media screen and (max-width: 768px) {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }

    @media screen and (max-width: 370px) {
        font-size: 1.2rem;
        line-height: 1.4rem;
    }

    @media screen and (max-height: 450px) {
        font-size: 1.2rem;
        line-height: 1.4rem;
    }

    &.gold {
        color: $gold1;
    }
}