@import './../../../assets/variables.scss';

.ico {
    height: 2.4rem;
    width: 2.4rem;
    flex-shrink: 0;
    
    path {
        fill: $gold1;
    }

    &_filled {
        background-color: $gold1;

        path {
            fill: $white;
        }
    }

    &_handler {
        @media screen and (hover) {
            cursor: pointer;
            transition: background-color 0.5s;

            path {
                transition: fill 0.5s;
            }
    
            &:hover {
                background-color: $gold1;

                path {
                    fill: $white;
                }

                &.ico_filled {
                    background-color: transparent;

                    path {
                        fill: $gold1;
                    }
                }
            }
        }
    }
}