@import './../../../assets/variables.scss';

.growFull {
    flex-grow: 1;
}

.contacts {
    width: 100dvw;
    flex-shrink: 0;
    height: 100%;
    position: relative;

    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 3.2rem;

        @media screen and (max-height: 800px) {
            row-gap: 1.2rem;
        }
    }

    &__detailsTitle {
        display: flex;

        @media screen and (max-height: 480px) {
            display: none;
        }
    }

    &__details {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 3.2rem;
        margin-bottom: 0.9rem;

        @media screen and (max-height: 800px) {
            row-gap: 1.2rem;
            margin-bottom: 0;
        }
    }

    &__data {
        width: 100%;
        display: flex;
        column-gap: 8rem;

        @media screen and (max-width: 650px) {
            column-gap: 1.6rem;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        column-gap: 1.6rem;

        @media screen and (max-width: 450px) {
            column-gap: 0.8rem;
        }

        &_first {
            @media screen and (max-width: 450px) {
                display: none;
            }
        }
    }

    &__ico {
        width: 4rem;
        height: 4rem;

        @media screen and (max-height: 800px) {
            width: 2.4rem;
            height: 2.4rem;
        }

        @media screen and (max-width: 540px) {
            width: 2rem;
            height: 2rem;
        }
    }

    &__itemData {
        display: flex;
        flex-direction: column;
        row-gap: 0.2rem;

        @media screen and (max-width: 540px) {
            p {
                font-size: 1rem;
                line-height: 1rem;
            }
        }
    }

    &__itemName {
        color: rgba($color: $black, $alpha: 0.3);
        font-size: 1.8rem;
        line-height: 1.8rem;
        font-weight: 700;
        letter-spacing: 0.02em;
        text-transform: uppercase;

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }

        @media screen and (max-width: 370px) {
            font-size: 1.2rem;
            line-height: 1.2rem;
        }

        @media screen and (max-height: 800px) {
            display: none;
        }
    }

    &__itemLink {
        display: flex;
        position: relative;
        white-space: nowrap;

        div {
            position: absolute;
            left: 0;
            pointer-events: none;
            width: 0;
            overflow: hidden;
            transition: width 0.5s;
            white-space: nowrap;

            p {
                color: $gold1 !important;
            }
        }

        @media screen and (hover) {
            cursor: pointer;

            &:hover div {
                width: 100%;
            }
        }
    }

    &__titleWrapper {
        display: flex;

        @media screen and (max-height: 900px) {
            display: none;
        }
    }

    &__finish {
        flex-grow: 1;
        background-color: $gray1;
        padding: 1.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 3.2rem;
        text-align: center;

        &::before,
        &::after {
            content: '';
            display: block;
            flex-grow: 1;
        }

        @media screen and (max-height: 1050px) {
            row-gap: 1.6rem;
        }

        @media screen and (max-height: 440px) {
            padding: 0.8rem;
        }
    }

    &__texts {
        width: 100%;
        max-width: 60rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
    }

    &__form {
        flex-grow: 1;
        background-color: $gray1;
        padding: 4rem;
        display: flex;
        flex-direction: column;
        row-gap: 3.2rem;

        @media screen and (max-height: 1050px) {
            row-gap: 1.6rem;
            padding: 2.4rem;
        }

        @media screen and (max-height: 900px) {
            flex-direction: row;
            column-gap: 1.2rem;
        }

        @media screen and (max-height: 440px) {
            padding: 0.8rem;
        }
    }

    &__formContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
        flex-grow: 1;

        @media screen and (max-height: 800px) {
            row-gap: 1.2rem;
        }
    }

    &__line {
        width: 100%;
        display: flex;
        column-gap: 2.4rem;
    }

    &__linePart {
        width: calc(50% - 1.2rem);
    }

    &__minLine {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
        flex-grow: 1;

        @media screen and (max-height: 1050px) {
            flex-direction: row;
            column-gap: 2.4rem;
            align-items: flex-end;
        }
    }

    &__minLinePart {
        width: 100%;
        display: flex;
        flex-direction: column;

        @media screen and (max-height: 1050px) {
            width: calc(50% - 1.2rem);
        }
    }
    &__minLinePart_growFull {
        height: 100%;
    }

    &__file {
        display: flex;
        align-items: center;
        column-gap: 1.6rem;
    }

    &__dnone {
        display: none;
    }

    &__fileIco {
        flex-shrink: 0;
        display: block;
        width: 4rem;
        height: 4rem;

        @media screen and (max-height: 440px) {
            width: 2.4rem;
            height: 2.4rem;
        }
    }

    &__fileText {
        color: $black;
        font-size: 1.7rem;
        line-height: 1.9rem;
        font-weight: 700;
        text-transform: uppercase;

        &_empty {
            color: rgba($color: $black, $alpha: 0.3);
        }

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }

        @media screen and (max-height: 500px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }
    }

    &__send {
        min-height: 5.1rem;
        width: 100%;
        border: 1px solid $gold1;
        background-color: transparent;
        color: $gold1;
        font-size: 1.6rem;
        line-height: 1.9rem;
        font-weight: 700;
        text-transform: uppercase;

        &_finish {
            width: fit-content;
            padding: 1.2rem 3.2rem;
            align-self: center !important;
        }

        @media screen and (hover) {
            cursor: pointer;
            transition:
                background-color 0.5s,
                color 0.5s;

            &:hover {
                background-color: $gold1;
                color: $white;
            }
        }

        @media screen and (max-height: 900px) {
            width: auto;
            padding: 0.5rem 2.4rem;
            min-width: 10rem;
            align-self: flex-end;
            min-height: 3rem;
        }

        @media screen and (max-height: 340px) {
            min-height: 2.1rem;
        }

        @media screen and (max-width: 540px) {
            min-width: 5rem;
            min-height: 2.4rem;
            padding: 0.5rem 1.2rem;
            font-size: 1.2rem;
            line-height: 1.4rem;
        }
    }

    &__footer {
        width: 100%;
        padding-top: 2.4rem;
        border-top: 1px solid $gold1;
        display: flex;
        column-gap: 1.2rem;
        justify-content: space-between;

        @media screen and (max-height: 800px) {
            padding-top: 1.2rem;
        }
    }

    &__part {
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;

        @media screen and (max-height: 800px) {
            row-gap: 0.8rem;
        }

        @media screen and (max-height: 360px) {
            flex-direction: row;
            column-gap: 1.2rem;
        }
    }

    &__gray {
        color: rgba($color: $black, $alpha: 0.5);
        font-size: 1.4rem;
        line-height: 1.4rem;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;

        @media screen and (max-height: 500px) {
            font-size: 1.2rem;
            line-height: 1.2rem;
        }

        @media screen and (max-width: 650px) {
            font-size: 1.2rem;
            line-height: 1.2rem;
        }

        @media screen and (max-width: 540px) {
            font-size: 0.8rem;
            line-height: 0.8rem;
        }
    }

    &__Left {
        display: flex;
        column-gap: 1.2rem;
    }

    &__link {
        display: flex;

        @media screen and (hover) {
            cursor: pointer;

            .contacts__linkSpan::after {
                content: attr(data-text);
                color: $gold1;
                position: absolute;
                left: 0;
                pointer-events: none;
                width: 0;
                overflow: hidden;
                transition: width 0.5s;
                white-space: nowrap;
            }

            &:hover {
                .contacts__linkSpan::after {
                    width: 100%;
                }
            }
        }
    }
}

.vcontacts {
    width: 100%;
    flex-shrink: 0;
    padding-bottom: 3.2rem;
    position: relative;

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 3.2rem;
    }

    &__detailsTitle {
        display: flex;
    }

    &__details {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 3.2rem;
        margin-bottom: 2.8rem;
    }

    &__dnoneFile {
        display: none;
    }

    &__data {
        width: 100%;
        display: flex;
        column-gap: 8rem;

        @media screen and (max-width: 1100px) {
            column-gap: 2.4rem;
        }

        @media screen and (max-width: 800px) {
            flex-direction: column;
            row-gap: 1.6rem;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        column-gap: 1.6rem;
    }

    &__textarea {
        height: 18rem;
        display: flex;
        flex-direction: column;
    }

    &__ico {
        width: 4rem;
        height: 4rem;
    }

    &__itemData {
        display: flex;
        flex-direction: column;
        row-gap: 0.2rem;
    }

    &__itemName {
        color: rgba($color: $black, $alpha: 0.3);
        font-size: 1.8rem;
        line-height: 1.8rem;
        font-weight: 700;
        letter-spacing: 0.02em;
        text-transform: uppercase;

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }
    }

    &__itemLink {
        display: flex;
        position: relative;
        white-space: nowrap;

        div {
            position: absolute;
            left: 0;
            pointer-events: none;
            width: 0;
            overflow: hidden;
            transition: width 0.5s;
            white-space: nowrap;

            p {
                color: $gold1 !important;
            }
        }

        @media screen and (hover) {
            cursor: pointer;

            &:hover div {
                width: 100%;
            }
        }
    }

    &__titleWrapper {
        display: flex;
    }

    &__form {
        flex-grow: 1;
        background-color: $gray1;
        padding: 4rem;
        display: flex;
        flex-direction: column;
        row-gap: 3.2rem;

        @media screen and (max-width: 768px) {
            padding: 1.6rem;
        }
    }

    &__formContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
    }

    &__line {
        width: 100%;
        display: flex;
        column-gap: 2.4rem;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            row-gap: 2.4rem;
        }
    }

    &__linePart {
        width: calc(50% - 1.2rem);

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    &__minLine {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
        flex-grow: 1;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            row-gap: 2.4rem;
        }
    }

    &__minLinePart {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__file {
        display: flex;
        align-items: center;
        column-gap: 1.6rem;
    }

    &__fileIco {
        flex-shrink: 0;
        display: block;
        width: 4rem;
        height: 4rem;
    }

    &__fileText {
        color: $black;
        font-size: 1.7rem;
        line-height: 1.9rem;
        font-weight: 700;
        text-transform: uppercase;

        &_empty {
            color: rgba($color: $black, $alpha: 0.3);
        }

        @media screen and (max-width: 768px) {
            font-size: 1.6rem;
            line-height: 1.9rem;
        }
    }

    &__send {
        min-height: 5.1rem;
        width: 100%;
        border: 1px solid $gold1;
        background-color: transparent;
        color: $gold1;
        font-size: 1.6rem;
        line-height: 1.9rem;
        font-weight: 700;
        text-transform: uppercase;

        @media screen and (hover) {
            cursor: pointer;
            transition:
                background-color 0.5s,
                color 0.5s;

            &:hover {
                background-color: $gold1;
                color: $white;
            }
        }
    }

    &__footer {
        width: 100%;
        padding-top: 2.4rem;
        border-top: 1px solid $gold1;
        display: flex;
        column-gap: 1.2rem;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            row-gap: 2.4rem;
        }
    }

    &__part {
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
    }

    &__gray {
        color: rgba($color: $black, $alpha: 0.5);
        font-size: 1.4rem;
        line-height: 1.4rem;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
    }

    &__Left {
        display: flex;
        column-gap: 1.2rem;
    }

    &__link {
        display: flex;

        @media screen and (hover) {
            cursor: pointer;

            .contacts__linkSpan::after {
                content: attr(data-text);
                color: $gold1;
                position: absolute;
                left: 0;
                pointer-events: none;
                width: 0;
                overflow: hidden;
                transition: width 0.5s;
                white-space: nowrap;
            }

            &:hover {
                .contacts__linkSpan::after {
                    width: 100%;
                }
            }
        }
    }
}
