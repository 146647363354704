@import "./fonts/DINPro/stylesheet.scss";
/*Fonts*/
$din-family: "Din-pro", sans-serif;
/*END Global*/

/*Colors*/
$white: #ffffff;
$gold: #D4B97B;
$gold1: #9A8555;
$gold3: #EDE7D9;
$black: #231F20;
$black1: #251611;
$gray: #F0EFF1;
$gray1: #F5F3EE;
$gray3: #FBFAF8;
$red: #9C1F2E;



$white1: #F7F7F7;
$white2: #f6f6f6;
$black2: #170D0F;
$gray2: #DBDBDB;
$gray3: #E8E8E8;
$gray4: #EDEDED;
$green: #1f9c34;
$sand: #EDE3D3;
/*END Colors*/