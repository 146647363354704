@import './../../../assets/variables.scss';

.h4 {
    color: $black;
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: 300;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
        font-size: 2rem;
        line-height: 2.3rem;
    }

    @media screen and (max-height: 500px) {
        font-size: 2rem;
        line-height: 2.3rem;
    }

    @media screen and (max-height: 400px) {
        font-size: 1.4rem;
        line-height: 1.6rem;
    }
}