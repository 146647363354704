@import './assets/variables.scss';
@import './assets/mixins.scss';
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';
@import 'react-phone-input-2/lib/style.css';
@import 'swiper/css';
@import 'swiper/css/free-mode';
@import 'swiper/css/navigation';
@import 'swiper/css/thumbs';

/*
1050
768
400
*/

* {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: $din-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeSpeed;
}

*,
:before,
:after {
    margin: 0;
    padding: 0;
    border: 0;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

video {
    background-color: transparent;
    border-bottom: 1px solid transparent; /* хак для предотвращения мелькания при загрузке на некоторых версиях ios */
    &::-webkit-media-controls-panel {
        display: none !important;
        -webkit-appearance: none;
        opacity: 0;
        visibility: hidden;
    }
    &::-webkit-media-controls-play-button {
        display: none !important;
        -webkit-appearance: none;
        opacity: 0;
        visibility: hidden;
    }
    &::-webkit-media-controls-start-playback-button {
        display: none !important;
        -webkit-appearance: none;
        opacity: 0;
        visibility: hidden;
    }
}

html {
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    height: 100dvh;
    min-height: 320px;
    min-width: 320px;
    width: 100%;

    &.noEvent {
        pointer-events: none;
    }

    &.horizontal {
        height: 100%;

        body,
        #root,
        main,
        .publicLayout {
            height: 100%;
        }
    }

    @media screen and (max-width: 2800px) {
        font-size: 12px;
    }

    @media screen and (max-width: 1920px) and (min-width: 1080px) {
        // font-size: 10px;
        font-size: clamp(5.625px, calc(5.625px + (10 - 5.625) * ((100vw - 1080px) / (1920 - 1080))), 10px);
    }
}

body {
    position: relative;
    padding: 0;
    margin: 0;
    border: 0;
    width: 100%;
    overflow-x: hidden;
    background-color: $white;
    font-family: $din-family;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.3rem;
    color: rgba($color: $gray, $alpha: 0.8);
    min-height: 100%;
    display: flex;
    flex-direction: column;

    &:has(.portal) {
        overflow: hidden;
    }
}

#root {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

a {
    cursor: pointer;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

select::-ms-expand {
    display: none;
}

input,
button,
textarea,
select {
    outline: none;
}

.container {
    width: 100%;
    max-width: 192rem;
    padding: 0 4rem;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        padding: 0 1.6rem;
    }

    &_long {
        max-width: none;
        flex-shrink: 0;
    }

    &_relative {
        position: relative;
    }
}

.horizontal {
    .container {
        @media screen and (max-height: 550px) {
            padding: 0 1.6rem;
        }
    }
}

.react-dropdown-select-dropdown-position-bottom.react-dropdown-select-dropdown {
    border: 1px solid rgba($color: $gray1, $alpha: 0.7);
    background: $white;
    border-radius: 0.8rem;
    padding: 0.5rem 0.2rem;
    box-shadow: none;
    z-index: 10;
    margin-top: 0.3rem;
}

.react-dropdown-select-dropdown-position-bottom .react-dropdown-select-item {
    padding: 0.5rem 1.4rem;
    color: rgba($color: $gray, $alpha: 0.8);
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    border-radius: 0.3rem;

    @media screen and (hover) {
        cursor: pointer;
        transition:
            background-color 0.5s,
            color 0.5s;

        &:hover {
            background-color: transparent;
            color: $red;
        }
    }

    &.react-dropdown-select-item-selected {
        background-color: $red;
        color: $white1;
    }
}

.react-dropdown-select {
    &.dropdown {
        width: 100%;
        min-height: 5.4rem;
        border: none !important;
        border-radius: 0.2rem;
        padding: 0.9rem 2.5rem;
        box-shadow: none !important;
        background-color: $white;

        @media screen and (hover) {
            cursor: pointer;
            transition: border-color 0.5s;

            &:hover {
                border-color: $red !important;
            }
        }

        @media screen and (max-width: 768px) {
            min-height: 5.2rem;
        }

        &.little {
            min-height: 4.1rem;
            padding: 0.5rem 0.8rem;
        }

        .react-dropdown-select-content {
            column-gap: 1rem;
            row-gap: 0.5rem;
        }

        .react-dropdown-select-dropdown-handle {
            margin: 0;
            display: flex;
            width: 2.2rem;
            height: 2.2rem;
            background: $white url(./assets/svg/chevron-down.svg) center no-repeat;
            background-size: contain;
            transition: transform 0.5s;

            svg {
                display: none;
            }
        }

        .react-dropdown-select-input,
        .react-dropdown-select-input::placeholder,
        .react-dropdown-select-content span {
            margin: 0;
            font-family: $din-family;
            color: $gray;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 300;
            background-color: $white;
            padding: 0;

            @media screen and (max-width: 768px) {
                font-size: 1.4rem;
                line-height: 2.1rem;
            }
        }

        .react-dropdown-select-dropdown {
            border: 1px solid $gray1;
            background: $white1;
            border-radius: 0.2rem;
            padding: 0.5rem 0.2rem;
            box-shadow: none;
            width: 100%;
        }

        .react-dropdown-select-item {
            padding: 0.5rem 1.4;
            font-family: $din-family;
            color: $gray;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 300;
            border-radius: 0.3rem;

            @media screen and (hover) {
                cursor: pointer;
                transition:
                    background-color 0.5s,
                    color 0.5s;

                &:hover {
                    background-color: $red;
                    color: $white1;
                }
            }

            @media screen and (max-width: 768px) {
                font-size: 1.4rem;
                line-height: 2.1rem;
            }

            &.react-dropdown-select-item-selected {
                background-color: $white;
                color: $red;
            }
        }
    }

    &.dropdown_gray {
        &.dropdown {
            border-color: rgba($color: $gray1, $alpha: 0.7);
            min-height: 5rem;

            @media screen and (hover) {
                &:hover {
                    background-color: $red;
                }
            }

            .react-dropdown-select-input,
            .react-dropdown-select-input::placeholder,
            .react-dropdown-select-content span {
                color: rgba($color: $gray, $alpha: 0.8);
            }

            .react-dropdown-select-dropdown {
                border-color: rgba($color: $gray1, $alpha: 0.7);
                max-height: 18rem;
            }

            .react-dropdown-select-item {
                @media screen and (hover) {
                    &:hover {
                        background-color: $red;
                        color: $white1;
                    }
                }
            }

            &.react-dropdown-select-item-selected {
                color: $red;
            }
        }

        .react-dropdown-select-dropdown-handle {
            filter: grayscale(1);
        }
    }

    &.dropdown.little {
        min-height: 4.1rem;
        padding: 0.5rem 0.8rem;

        .react-dropdown-select-input,
        .react-dropdown-select-input::placeholder,
        .react-dropdown-select-content span {
            font-size: 1.4rem;
            line-height: 1.7rem;
        }
    }

    &.dropdown_error {
        &.dropdown {
            border-color: $red;
        }
    }
}

.table-sort.dropdown {
    padding: 0;
    min-height: 0;
    border: none !important;
    min-width: 12rem;
}

.table-sort.react-dropdown-select.dropdown .react-dropdown-select-input,
.react-dropdown-select.dropdown .react-dropdown-select-input::placeholder,
.react-dropdown-select.dropdown .react-dropdown-select-content span {
    font-size: 1.4rem;
    line-height: 2.1rem;
    font-family: $din-family;
}

.swiper-main-picture {
    width: 100%;
}

.swiper-pagination {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 0.6rem;
    row-gap: 0.6rem;
    margin-top: 2.4rem;

    .swiper-pagination-bullet {
        background-color: $gold1;
        opacity: 0.3;
        width: 0.8rem;
        height: 0.4rem;
        border-radius: 0.4rem;
        transition:
            opacity 0.5s,
            width 0.5s;

        @media screen and (hover) {
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }

        &-active {
            opacity: 1;
            width: 3.2rem;
        }
    }
}

// .swiper-thumb-picture {
//     .swiper-slide  {
//         position: relative;

//         &::after {
//             content: "";
//             position: absolute;
//             pointer-events: none;
//             inset: 0;
//             background-color: rgba($color: $black1, $alpha: 0.4);
//         }
//     }
// }

body:has(.ymaps-2-1-79-map:nth-child(2)) div > .ymaps-2-1-79-map:nth-child(1) {
    display: none;
}
