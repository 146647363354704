@import './../../../assets/variables.scss';

.link {
    max-width: 100%;
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
    justify-content: space-between;

    @media screen and (hover) {
        cursor: pointer;

        .link__title::after {
            content: attr(data-text);
            color: $gold1;
            position: absolute;
            left: 0;
            pointer-events: none;
            width: 0;
            overflow: hidden;
            transition: width 0.5s;
            white-space: nowrap;
        }

        .link__ico {
            transition:
                stroke 0.5s,
                fill 0.5s;
        }

        &.routineAnim {
            .link__title {
                transition: color 0.5s;
            }
        }

        &:hover {
            .link__title::after {
                width: 100%;
            }

            .link__ico {
                fill: $gold1;

                &_stroke {
                    stroke: $gold1;
                    fill: none;
                }
            }

            &.routineAnim {
                .link__title {
                    color: $gold1;
                }
            }
        }
    }

    &__product {
        font-size: 20px !important;
    }

    &__title {
        color: rgba($color: $black, $alpha: 0.2);
        font-size: 4.8rem;
        line-height: 4.3rem;
        font-weight: 500;
        position: relative;
        white-space: nowrap;
        text-transform: uppercase;
        transition: all 0.5s;

        @media screen and (max-width: 768px) {
            font-size: 3.2rem;
            line-height: 2.8rem;
        }

        @media screen and (max-height: 550px) {
            font-size: 3.2rem;
            line-height: 2.8rem;
        }

        @media screen and (max-height: 400px) {
            font-size: 2.4rem;
            line-height: 2.2rem;
        }

        &.link__black {
            color: $black;
        }

        &.link__textLink {
            font-size: inherit;
            line-height: inherit;
            font-weight: 500;
            text-transform: inherit;
            color: inherit;
            letter-spacing: -0.06em;
        }

        &.link__subMenuLink {
            letter-spacing: -0.06em;
        }
    }

    &__ico {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        fill: rgba($color: $black, $alpha: 0.2);
        flex-shrink: 0;

        &_stroke {
            stroke: $black;
            fill: none;
        }
    }

    &_text {
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
        text-transform: inherit;
        color: inherit;
        display: inline-flex;
    }

    &_active {
        pointer-events: none;

        .link__title {
            color: $gold1;
            font-size: 12.8rem;
            line-height: 11.5rem;

            &.link__subMenuLink {
                font-size: 9.8rem;
                line-height: 9.8rem;
                letter-spacing: -0.08em;

                @media screen and (max-width: 768px) {
                    font-size: 5rem;
                    line-height: 4.5rem;
                }

                @media screen and (max-height: 550px) {
                    font-size: 5rem;
                    line-height: 4.5rem;
                }

                @media screen and (max-height: 400px) {
                    font-size: 4rem;
                    line-height: 3.5rem;
                }
            }

            &.link__textLink {
                font-size: inherit;
                line-height: inherit;
                font-weight: inherit;
                text-transform: inherit;
                color: inherit;
            }
        }

        .link__ico {
            fill: $gold1;

            &_stroke {
                stroke: $gold1;
                fill: none;
            }
        }
    }
}
