@import './../../assets/variables.scss';

.preloader {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    background-color: $white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
        width: 36rem;
        height: 36rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid $gray;
        position: relative;

        @media screen and (max-width: 500px) {
            width: 25rem;
            height: 25rem;
        }

        span {
            display: none;
        }
    }

    &__inside {
        position: absolute;
        inset: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 2.4rem;
    }

    &__text {
        color: $gold1;
        font-size: 4.8rem;
        line-height: 4.3rem;
        font-weight: 500;

        @media screen and (max-width: 1400px) {
            font-size: 3.2rem;
            line-height: 3.2rem;
        }
    }

    &__svg {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 15rem;
        height: 15.8rem;
    }

    @keyframes hammer {
        0% {
            transform: translateX(0) rotate(-54.515deg);
        }
        50% {
            transform: translate(20px, 40px) rotate(0deg);
        }
        100% {
            transform: translateX(0) rotate(-54.515deg);
        }
    }

    &__hammer {
        display: block;
        flex-shrink: 0;
        height: 3.2rem;
        width: 7.3rem;
        // animation: hammer 1.6s infinite cubic-bezier(0.22, 1, 0.36, 1) forwards;

        top: 1.9rem;
        left: -0.9rem;
        position: absolute;
        transform-origin: center;
        // transform: rotate(-54.515deg);
    }

    &__anvil {
        display: block;
        flex-shrink: 0;
        width: 13.4rem;
        height: 6.8rem;
        position: absolute;

        bottom: 0;
        left: 1rem;
    }

    &__splash {
        position: absolute;
        width: 13.8rem;

        top: 0.5rem;
        left: 1.2rem;
    }
}

.loader {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    background-color: $gray1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &__spinner {
        font-size: 1rem;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        position: relative;
        text-indent: -9999em;
        animation: mulShdSpin 1.1s infinite ease;
        transform: translateZ(0);
    }

    @keyframes mulShdSpin {
        0%,
        100% {
            box-shadow:
                0em -2.6em 0em 0em #9a8555,
                1.8em -1.8em 0 0em rgba(154, 133, 85, 0.2),
                2.5em 0em 0 0em rgba(154, 133, 85, 0.2),
                1.75em 1.75em 0 0em rgba(154, 133, 85, 0.2),
                0em 2.5em 0 0em rgba(154, 133, 85, 0.2),
                -1.8em 1.8em 0 0em rgba(154, 133, 85, 0.2),
                -2.6em 0em 0 0em rgba(154, 133, 85, 0.5),
                -1.8em -1.8em 0 0em rgba(154, 133, 85, 0.7);
        }
        12.5% {
            box-shadow:
                0em -2.6em 0em 0em rgba(154, 133, 85, 0.7),
                1.8em -1.8em 0 0em #9a8555,
                2.5em 0em 0 0em rgba(154, 133, 85, 0.2),
                1.75em 1.75em 0 0em rgba(154, 133, 85, 0.2),
                0em 2.5em 0 0em rgba(154, 133, 85, 0.2),
                -1.8em 1.8em 0 0em rgba(154, 133, 85, 0.2),
                -2.6em 0em 0 0em rgba(154, 133, 85, 0.2),
                -1.8em -1.8em 0 0em rgba(154, 133, 85, 0.5);
        }
        25% {
            box-shadow:
                0em -2.6em 0em 0em rgba(154, 133, 85, 0.5),
                1.8em -1.8em 0 0em rgba(154, 133, 85, 0.7),
                2.5em 0em 0 0em #9a8555,
                1.75em 1.75em 0 0em rgba(154, 133, 85, 0.2),
                0em 2.5em 0 0em rgba(154, 133, 85, 0.2),
                -1.8em 1.8em 0 0em rgba(154, 133, 85, 0.2),
                -2.6em 0em 0 0em rgba(154, 133, 85, 0.2),
                -1.8em -1.8em 0 0em rgba(154, 133, 85, 0.2);
        }
        37.5% {
            box-shadow:
                0em -2.6em 0em 0em rgba(154, 133, 85, 0.2),
                1.8em -1.8em 0 0em rgba(154, 133, 85, 0.5),
                2.5em 0em 0 0em rgba(154, 133, 85, 0.7),
                1.75em 1.75em 0 0em #9a8555,
                0em 2.5em 0 0em rgba(154, 133, 85, 0.2),
                -1.8em 1.8em 0 0em rgba(154, 133, 85, 0.2),
                -2.6em 0em 0 0em rgba(154, 133, 85, 0.2),
                -1.8em -1.8em 0 0em rgba(154, 133, 85, 0.2);
        }
        50% {
            box-shadow:
                0em -2.6em 0em 0em rgba(154, 133, 85, 0.2),
                1.8em -1.8em 0 0em rgba(154, 133, 85, 0.2),
                2.5em 0em 0 0em rgba(154, 133, 85, 0.5),
                1.75em 1.75em 0 0em rgba(154, 133, 85, 0.7),
                0em 2.5em 0 0em #9a8555,
                -1.8em 1.8em 0 0em rgba(154, 133, 85, 0.2),
                -2.6em 0em 0 0em rgba(154, 133, 85, 0.2),
                -1.8em -1.8em 0 0em rgba(154, 133, 85, 0.2);
        }
        62.5% {
            box-shadow:
                0em -2.6em 0em 0em rgba(154, 133, 85, 0.2),
                1.8em -1.8em 0 0em rgba(154, 133, 85, 0.2),
                2.5em 0em 0 0em rgba(154, 133, 85, 0.2),
                1.75em 1.75em 0 0em rgba(154, 133, 85, 0.5),
                0em 2.5em 0 0em rgba(154, 133, 85, 0.7),
                -1.8em 1.8em 0 0em #9a8555,
                -2.6em 0em 0 0em rgba(154, 133, 85, 0.2),
                -1.8em -1.8em 0 0em rgba(154, 133, 85, 0.2);
        }
        75% {
            box-shadow:
                0em -2.6em 0em 0em rgba(154, 133, 85, 0.2),
                1.8em -1.8em 0 0em rgba(154, 133, 85, 0.2),
                2.5em 0em 0 0em rgba(154, 133, 85, 0.2),
                1.75em 1.75em 0 0em rgba(154, 133, 85, 0.2),
                0em 2.5em 0 0em rgba(154, 133, 85, 0.5),
                -1.8em 1.8em 0 0em rgba(154, 133, 85, 0.7),
                -2.6em 0em 0 0em #9a8555,
                -1.8em -1.8em 0 0em rgba(154, 133, 85, 0.2);
        }
        87.5% {
            box-shadow:
                0em -2.6em 0em 0em rgba(154, 133, 85, 0.2),
                1.8em -1.8em 0 0em rgba(154, 133, 85, 0.2),
                2.5em 0em 0 0em rgba(154, 133, 85, 0.2),
                1.75em 1.75em 0 0em rgba(154, 133, 85, 0.2),
                0em 2.5em 0 0em rgba(154, 133, 85, 0.2),
                -1.8em 1.8em 0 0em rgba(154, 133, 85, 0.5),
                -2.6em 0em 0 0em rgba(154, 133, 85, 0.7),
                -1.8em -1.8em 0 0em #9a8555;
        }
    }
}
