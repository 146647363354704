@import './../../../assets/variables.scss';

.history {
    min-width: 100dvw;
    flex-shrink: 0;
    height: 100%;
    position: relative;

    &__content {
        display: flex;
        column-gap: 10rem;
        height: 100%;

        @media screen and (max-height: 925px) and (max-width: 1450px) {
            column-gap: 10rem;
        }

        @media screen and (max-width: 768px) {
            column-gap: 8rem;
        }
    }

    &__firstPictureWrapper {
        flex-shrink: 0;
        height: 100%;
        position: relative;
    }

    &__firstPic {
        display: block;
        height: 100%;
        aspect-ratio: 2/3;
        object-fit: cover;
    }

    &__texts {
        flex-shrink: 0;
        max-width: 134.4rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        row-gap: 4.6rem;

        h2 {
            max-width: 1420px;
        }

        @media screen and (max-height: 925px) and (max-width: 1450px) {
            width: 98rem;
            row-gap: 3.2rem;
        }

        @media screen and (max-width: 1400px) {
            width: 140rem;
            row-gap: 3.2rem;
        }

        @media screen and (max-width: 900px) {
            width: 100rem;
            row-gap: 2.8rem;
        }

        @media screen and (max-width: 550px) {
            width: 50rem;
            row-gap: 2.4rem;
        }

        @media screen and (max-height: 700px) and (min-width: 950px) {
            width: 100rem;
            row-gap: 2.8rem;
        }

        @media screen and (max-height: 480px) {
            width: 40rem;
            row-gap: 2.4rem;
        }
    }

    &__intro {
        flex-shrink: 0;
        width: 58.4rem;
        display: flex;
        flex-direction: column;
        row-gap: 9.6rem;

        @media screen and (max-height: 925px) and (max-width: 1450px) {
            width: 50rem;
        }

        @media screen and (max-width: 1400px) {
            row-gap: 5rem;
            width: 58.4rem;
        }

        @media screen and (max-width: 900px) {
            row-gap: 4rem;
            width: 58.4rem;
        }

        @media screen and (max-width: 550px) {
            row-gap: 2.4rem;
            width: 58.4rem;
        }

        @media screen and (max-height: 700px) and (min-width: 950px) {
            row-gap: 2.4rem;
            width: 58.4rem;
        }

        @media screen and (max-height: 480px) {
            row-gap: 2.4rem;
            width: 58.4rem;
        }

        &_top {
            row-gap: 2.4rem;

            .history__introPic {
                object-position: center;
            }
        }
    }

    &__introPic {
        width: 100%;
        display: block;
        flex-grow: 1;
        object-fit: cover;
        object-position: left;
    }

    &__logo {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        width: 106px;
        height: auto;
    }

    &__master {
        flex-shrink: 0;
        padding: 0 44.3rem 8rem;
        width: 214.2rem;
        position: relative;

        @media screen and (max-height: 925px) and (max-width: 1450px) {
            width: 171rem;
            padding: 0 37rem 6rem;
        }

        @media screen and (max-width: 1400px) {
            width: 190rem;
            padding: 0 34rem 6rem;
        }

        @media screen and (max-width: 900px) {
            width: 130rem;
            padding: 0 30rem 6rem;
        }

        @media screen and (max-width: 550px) {
            width: 80rem;
            padding: 0 15rem 4rem;
        }

        @media screen and (max-height: 700px) and (min-width: 950px) {
            width: 170rem;
            padding: 0 34rem 6rem;
        }

        @media screen and (max-height: 480px) {
            width: 80rem;
            padding: 0 15rem 4rem;
        }
    }

    &__masterGold {
        background-color: $gold1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 23.8rem;
        right: 23.8rem;
        width: calc(100% - 47.6rem);

        @media screen and (max-width: 1400px) {
            left: 20rem;
            right: 20rem;
            width: calc(100% - 40rem);
        }

        @media screen and (max-width: 900px) {
            left: 18rem;
            right: 15rem;
            width: calc(100% - 30rem);
        }

        @media screen and (max-width: 550px) {
            left: 8rem;
            right: 8rem;
            width: calc(100% - 16rem);
        }

        @media screen and (max-height: 700px) and (min-width: 950px) {
            left: 20rem;
            right: 20rem;
            width: calc(100% - 40rem);
        }

        @media screen and (max-height: 480px) {
            left: 8rem;
            right: 8rem;
            width: calc(100% - 16rem);
        }
    }

    &__masterParallax {
        position: absolute;
        z-index: 1;
        inset: 0;

        & > div {
            position: relative;
            width: 100%;
            height: 100%;
        }

        & > div > div {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 2% 0;
        }
    }

    &__masterTextWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        position: relative;
        z-index: 2;
    }

    &__masterText {
        font-size: 9.6rem;
        line-height: 8.6rem;
        font-weight: 300;
        color: $white;
        text-transform: uppercase;

        @media screen and (max-height: 925px) and (max-width: 1450px) {
            font-size: 8rem;
            line-height: 8rem;
        }

        @media screen and (max-width: 1400px) {
            font-size: 8rem;
            line-height: 8rem;
        }

        @media screen and (max-width: 900px) {
            font-size: 5rem;
            line-height: 5rem;
        }

        @media screen and (max-width: 550px) {
            font-size: 3.2rem;
            line-height: 2.8rem;
        }

        @media screen and (max-height: 700px) and (min-width: 950px) {
            font-size: 7rem;
            line-height: 7rem;
        }

        @media screen and (max-height: 480px) {
            font-size: 3.2rem;
            line-height: 2.8rem;
        }
    }

    &__masterPic {
        height: 100%;
        width: auto;
        display: block;
        flex-shrink: 0;
        align-self: flex-start;

        &_right {
            align-self: flex-end;
        }
    }
}

.vhistory {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8rem;

    @media screen and (max-width: 768px) {
        row-gap: 3.2rem;
    }

    &__firstPicWrap {
        width: 100%;
        position: relative;
        video {
            aspect-ratio: 1/1;
            object-fit: cover;
            object-position: center;
        }
    }

    &__firstPic {
        width: 100%;
        display: block;

        img {
            width: 100%;
            display: block;
        }
    }

    &__logo {
        display: block;
        width: 14rem;
        height: 18rem;
        position: absolute;
        bottom: 4.8rem;
        left: calc(50% - 7rem);

        @media screen and (max-width: 768px) {
            width: 7rem;
            height: 9rem;
            bottom: 2.4rem;
            left: calc(50% - 3.5rem);
        }
    }

    &__texts {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
    }

    &__intro {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
    }

    &__introPic {
        width: 100%;
        display: block;
        aspect-ratio: 1/1;
        object-fit: cover;
        object-position: center;
    }

    &__master {
        width: calc(100% + 8rem);
        position: relative;
        padding: 0 4rem 1rem;
        margin: 0 -4rem;
        background-color: $gold1;
        background-clip: content-box;
        overflow: hidden;

        @media screen and (max-width: 768px) {
            padding: 0 1.6rem 1rem;
            margin: 0 -1.6rem;
            width: calc(100% + 3.2rem);
        }
    }

    &__masterParallax {
        position: absolute;
        inset: 0;
        overflow: hidden;

        & > div {
            position: relative;
            width: 100%;
            height: 100%;
        }

        & > div > div {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 2% 0;
        }
    }

    &__masterParallax1 {
        position: absolute;
        bottom: 1rem;
        left: 0;
        height: 83.8rem;

        @media screen and (max-width: 1400px) {
            height: 54rem;
        }

        @media screen and (max-width: 900px) {
            height: 41.9rem;
        }
    }

    &__masterParallax2 {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 98.8rem;

        @media screen and (max-width: 1400px) {
            height: 65.4rem;
        }

        @media screen and (max-width: 900px) {
            height: 49.4rem;
        }
    }

    &__masterPic {
        height: 100%;
        width: auto;
        display: block;
        flex-shrink: 0;

        @media screen and (max-width: 400px) {
            transform: translateX(2rem);
        }

        @media screen and (max-width: 350px) {
            transform: translateX(3rem);
        }

        &_r {
            @media screen and (max-width: 450px) {
                transform: translateX(-10rem);
            }

            @media screen and (max-width: 400px) {
                transform: translateX(-12rem);
            }

            @media screen and (max-width: 350px) {
                transform: translateX(-13rem);
            }
        }
    }

    &__masterText {
        padding: 6rem 3.9rem 97rem;
        font-size: 9.6rem;
        line-height: 8.6rem;
        font-weight: 300;
        color: $white;
        text-transform: uppercase;

        @media screen and (max-width: 1400px) {
            font-size: 8rem;
            line-height: 8rem;
            padding-bottom: 64.7rem;
        }

        @media screen and (max-width: 900px) {
            font-size: 5rem;
            line-height: 5rem;
            padding-bottom: 48.5rem;
        }

        @media screen and (max-width: 550px) {
            font-size: 3.2rem;
            line-height: 2.8rem;
        }
    }
}
