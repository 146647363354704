@font-face {
    font-family: 'Din-pro';
    src: url("./DINPro-CondensedLight.ttf") format("truetype");
    font-style: normal; 
    font-weight: 300; 
}

@font-face {
    font-family: 'Din-pro';
    src: url("./DINPro-CondensedLightItalic.ttf") format("truetype"); 
    font-style: italic; 
    font-weight: 300; 
}

@font-face {
    font-family: 'Din-pro';
    src: url("./dinpro_condensedregular.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 400; 
}

@font-face {
    font-family: 'Din-pro';
    src: url("./dinpro_condensedmedium.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 500; 
}

@font-face {
    font-family: 'Din-pro';
    src: url("./dinpro_condensedmediumitalic.ttf") format("truetype"); 
    font-style: italic; 
    font-weight: 500; 
}

@font-face {
    font-family: 'Din-pro';
    src: url("./DINPro-CondensedBold.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 700; 
}

@font-face {
    font-family: 'Din-pro';
    src: url("./DINPro-CondensedBoldItalic.ttf") format("truetype"); 
    font-style: italic; 
    font-weight: 700; 
}

@font-face {
    font-family: 'Din-pro';
    src: url("./dinpro_condensedblack.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 900; 
}

@font-face {
    font-family: 'Din-pro';
    src: url("./dinpro_condensedblackitalic.ttf") format("truetype"); 
    font-style: italic; 
    font-weight: 900; 
}

