@import './../assets/variables.scss';

.policyLayout {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 2.4rem;
}

.outternalLayout {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__vheader {
        position: static;
    }

    &__logo {
        flex-shrink: 0;
        width: 18rem;
        height: 3.2rem;

        @media screen and (max-width: 768px) {
            width: 12rem;
            height: 2.1rem;
        }

        @media screen and (hover) {
            cursor: pointer;
            transition: filter 0.5s;
    
            &:hover {
                filter: brightness(0);
            }
        }
    }
}
