@import './../../assets/variables.scss';

.portal {
    position: fixed;
    z-index: 10;
    inset: 0;
    top: 9.6rem;
    bottom: 9.6rem;
    width: 100%;
    height: calc(100% - 19.2rem);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: $white;
    opacity: 0;
    transition: opacity 0.5s;

    &_vertical {
        bottom: 0;
        height: calc(100% - 9.6rem);
    }

    @media screen and (max-height: 650px) {
        top: 5rem;
        bottom: 5rem;
        height: calc(100% - 10rem);

        &_vertical {
            bottom: 0;
            height: calc(100% - 5rem);
        }
    }

    @media screen and (max-width: 768px) {
        top: 5rem;
        bottom: 5rem;
        height: calc(100% - 10rem);

        &_vertical {
            bottom: 0;
            height: calc(100% - 5rem);
        }
    }

    &_show {
        opacity: 1;
    }
}