@import './../../../assets/variables.scss';

.h2 {
    color: $black;
    font-size: 12.8rem;
    line-height: 11.5rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: -0.04em;

    // @media screen and (max-height: 925px) and (max-width: 1450px) {
    //     font-size: 10.5rem;
    //     line-height: 10.5rem;
    // }

    // @media screen and (max-width: 1400px) {
    //     font-size: 8rem;
    //     line-height: 8rem;
    // }

    @media screen and (max-width: 900px) {
        font-size: 6rem;
        line-height: 6rem;
    }

    @media screen and (max-width: 768px) {
        font-size: 5rem;
        line-height: 5rem;
    }

    @media screen and (max-width: 550px) {
        font-size: 3.1rem;
        line-height: 4rem;
    }

    @media screen and (max-height: 700px) and (min-width: 950px) {
        font-size: 8rem;
        line-height: 8rem;
    }

    @media screen and (max-height: 480px) {
        font-size: 3.1rem;
        line-height: 4rem;
    }
}
