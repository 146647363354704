@import './../../assets/variables.scss';

.header {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 9.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    background-color: $white;

    // @media screen and (max-height: 925px) and (max-width: 1450px) {
    //     padding: 0.5rem 0;
    //     min-height: 7rem;
    // }

    @media screen and (max-height: 650px) {
        padding: 0.5rem 0;
        min-height: 5rem;
    }

    @media screen and (max-width: 768px) {
        padding: 0.5rem 0;
        min-height: 5rem;
    }

    &__container {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 2.4rem;
    }

    &__logo {
        flex-shrink: 0;
        width: 18rem;
        height: 3.2rem;

        @media screen and (max-width: 768px) {
            width: 12rem;
            height: 2.1rem;
        }

        @media screen and (hover) {
            cursor: pointer;
            transition: filter 0.5s;

            &:hover {
                filter: brightness(0);
            }
        }
    }

    &__dataWrapper {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
    }

    &__data {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 1.2rem;

        @media screen and (hover) {
            cursor: pointer;

            p {
                transition: color 0.5s;
            }

            &:hover {
                p {
                    color: $black1 !important;
                }

                .burger__line {
                    background-color: $black1;
                }
            }
        }
    }

    &__menu {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        column-gap: 2.4rem;

        @media screen and (max-width: 768px) {
            align-self: flex-end;
            height: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 5rem 2rem 2rem;
            row-gap: 4rem;
            width: 36rem;
            max-width: 100%;
            background-color: $white1;
        }

        @media screen and (max-width: 450px) {
            width: 100%;
            align-items: center;
            justify-content: center;
        }
    }

    &__nav {
        max-width: 100%;
        width: fit-content;
        margin-left: auto;
        margin-right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        row-gap: 4rem;
        transition: all 0.5s;
        max-height: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        @media screen and (max-height: 1650px) {
            row-gap: 2rem;
        }

        @media screen and (max-height: 450px) {
            row-gap: 1rem;
        }
    }
}

.burger {
    width: 2.7rem;
    height: 1.8rem;
    padding: 0.1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__line {
        width: 2.4rem;
        height: 2px;
        background-color: $gold1;
        transition:
            transform 0.5s,
            background-color 0.5s;
    }

    &.active {
        .burger__line:nth-child(2) {
            display: none;
        }

        .burger__line:nth-child(1) {
            transform: translateY(0.7rem) rotateZ(45deg);
        }

        .burger__line:nth-child(3) {
            transform: translateY(-0.7rem) rotateZ(-45deg);
        }
    }
}

.vheader {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    background-color: $white;

    &__container {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 2.4rem;
        justify-content: space-between;
    }

    &__left {
        display: flex;
        align-items: center;
        column-gap: 2.4rem;
    }

    &__logoWrapper {
        display: flex;
        flex-shrink: 0;
    }

    &__langs {
        display: flex;
        flex-shrink: 0;
        column-gap: 0.8rem;
    }

    &__lang {
        color: rgba($color: #231f20, $alpha: 0.2);
        font-size: 1.8rem;
        line-height: 1.8rem;
        font-weight: 700;
        letter-spacing: 0.06em;
        position: relative;
        white-space: nowrap;
        text-transform: uppercase;
        background-color: transparent;
        border: none;

        @media screen and (max-height: 650px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }

        .vheader__lang__hover {
            color: $gold1;
            position: absolute;
            left: 0;
            pointer-events: none;
            width: 0;
            overflow: hidden;
            transition: width 0.5s;
            white-space: nowrap;
        }

        @media screen and (hover) {
            cursor: pointer;

            &:hover {
                .vheader__lang__hover {
                    width: 100%;
                }
            }
        }

        &.active .vheader__lang__hover {
            width: 100%;
        }
    }

    &__right {
        display: flex;
        align-items: center;
        column-gap: 1.2rem;

        @media screen and (hover) {
            cursor: pointer;

            p {
                transition: color 0.5s;
            }

            &:hover {
                p {
                    color: $black1 !important;
                }

                .burger__line {
                    background-color: $black1;
                }
            }
        }
    }

    &__progress {
        width: calc(100% - 8rem);
        height: 1px;
        display: flex;
        background-color: rgba($color: #231f20, $alpha: 0.2);
        position: absolute;
        bottom: 0;
        left: 4rem;
        right: 4rem;

        @media screen and (max-width: 768px) {
            left: 1.6rem;
            right: 1.6rem;
            width: calc(100% - 3.2rem);
        }

        @media screen and (max-height: 550px) {
            left: 1.6rem;
            right: 1.6rem;
            width: calc(100% - 3.2rem);
        }
    }

    &__progressBar {
        height: 100%;
        background-color: $gold1;
        transition: width 0.3s;
    }

    &__nav {
        max-width: 100%;
        width: fit-content;
        margin: 0 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        row-gap: 4rem;
        transition: all 0.5s;

        @media screen and (max-height: 1650px) {
            row-gap: 2rem;
        }

        @media screen and (max-height: 450px) {
            row-gap: 1rem;
        }
    }
}
