@import './../../assets/variables.scss';

.horizontal {
    display: flex;
    overflow-x: scroll;
    height: 100%;
    scroll-snap-type: none;
    scroll-behavior: smooth;
    padding: 9.6rem 0;

    // @media screen and (max-height: 925px) and (max-width: 1450px) {
    //     padding: 7rem 0;
    // }

    @media screen and (max-height: 650px) {
        padding: 5rem 0;
    }

    @media screen and (max-width: 768px) {
        padding: 5rem 0;
    }

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    & > div {
        overflow-y: hidden !important;
    }

    &__slide {
        flex-shrink: 0;
        height: 100%;
        min-width: 100dvw;
        background-color: $white;
        display: flex;
    }
}
