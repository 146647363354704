@import './../../assets/variables.scss';

.privacy {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;
    flex-grow: 1;
    background-color: $gray1;
    padding: 4rem;

    @media screen and (max-width: 768px) {
        padding: 1.6rem;
    }

    & > div {
        display: flex;
        flex-direction: column;
        row-gap: 3.2rem;
    }

    &__container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__block {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
    }

    & > div p {
        color: $black;
        font-size: 2.4rem;
        line-height: 2.8rem;
        font-weight: 300;
        letter-spacing: 0.02em;

        &_small {
            font-size: 1.8rem;
            line-height: 2.1rem;
        }

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 2.1rem;
        }

        @media screen and (max-width: 370px) {
            font-size: 1.2rem;
            line-height: 1.4rem;
        }

        @media screen and (max-height: 450px) {
            font-size: 1.2rem;
            line-height: 1.4rem;
        }

        &.gold {
            color: $gold1;
        }
    }

    h2 {
        color: $gold1;
        font-size: 1.8rem;
        line-height: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.06em;

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }

        @media screen and (max-height: 500px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }

        @media screen and (max-height: 550px) {
            font-size: 1.2rem;
            line-height: 1.2rem;
        }
    }

    h1 {
        color: $black;
        font-size: 12.8rem;
        line-height: 11.5rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: -0.04em;

        @media screen and (max-width: 900px) {
            font-size: 6rem;
            line-height: 6rem;
        }

        @media screen and (max-width: 768px) {
            font-size: 5rem;
            line-height: 5rem;
        }

        @media screen and (max-width: 550px) {
            font-size: 3.1rem;
            line-height: 4rem;
        }

        @media screen and (max-height: 700px) and (min-width: 950px) {
            font-size: 8rem;
            line-height: 8rem;
        }

        @media screen and (max-height: 480px) {
            font-size: 3.1rem;
            line-height: 4rem;
        }
    }
}
