@import './../../../assets/variables.scss';

.products {
    min-width: 100dvw;
    flex-shrink: 0;
    height: 100%;
    position: relative;

    &__content {
        display: flex;
        height: 100%;
        padding-left: 0 !important;
        border-left: 2.4rem solid $white;
        perspective: 2500px;
        transition: 1s;
        @media screen and (max-width: 768px) {
            border-left: none;
        }

        @media screen and (max-height: 550px) {
            border-left: none;
        }
    }

    &__submenu {
        position: sticky;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        min-width: 43.5rem;
        background: linear-gradient(to right, $white 90%, rgba($color: $white, $alpha: 0) 100%);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        row-gap: 2.4rem;
        padding-left: 1.6rem;

        @media screen and (max-height: 550px) {
            row-gap: 1.2rem;
            min-width: 28rem;
        }

        @media screen and (max-height: 400px) {
            padding-top: 0.8rem;
        }

        @media screen and (max-width: 450px) {
            min-width: 25rem;
        }
    }

    &__bottleWrapper {
        flex-shrink: 0;
        height: 100%;
        display: flex;
        overflow: hidden;
    }

    @keyframes scaleUp {
        0% {
            transform: scale(0.5);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes scaleDown {
        0% {
            transform: scale(1);
        }
        99% {
            transform: scale(0.5);
        }
        100% {
            opacity: 0;
        }
    }

    &__bottle {
        width: fit-content;
        height: 100%;
        flex-shrink: 0;
        transition: background-color 1s;
        cursor: pointer;

        &:not(:hover) {
            background-color: transparent !important;
        }

        &_show {
            animation: scaleUp 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
        }

        &_hide {
            animation: scaleDown 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
        }
    }

    &__bottlePic {
        display: block;
        width: auto;
        height: 100%;
        filter: none;
        transition: filter 0.5s;

        &_gray {
            filter: grayscale(1);
        }
    }

    &__liList {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;
        animation: showLi 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    }

    &__li {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 1.6rem;

        &::before {
            content: '';
            display: block;
            width: 0.5rem;
            height: 0.5rem;
            flex-shrink: 0;
            border-radius: 50%;
            background-color: rgba($color: $gold1, $alpha: 0.3);
        }
    }

    &__clearIco {
        width: 2.4rem;
        height: 2.4rem;
        display: block;
        flex-shrink: 0;
    }

    &__clear {
        max-width: 100%;
        width: fit-content;
        display: flex;
        align-items: center;
        column-gap: 1.6rem;
        margin-bottom: 1.6rem;

        @media screen and (hover) {
            cursor: pointer;
            transition: filter 0.5s;

            &:hover {
                filter: grayscale(1) brightness(0);
            }
        }

        @media screen and (max-height: 550px) {
            margin-bottom: 0;
        }
    }
}

@keyframes pageFlip {
    0% {
        transform: rotateY(0deg);
        transform-origin: left;
        opacity: 1;
    }
    100% {
        transform: rotateY(-180deg);
        transform-origin: left;
        opacity: 0;
    }
}

.product {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 43.5rem;
    width: calc(100% - 43.5rem);
    z-index: 1;
    transform-style: preserve-3d;
    transform-origin: 20% 50%;

    @media screen and (max-height: 550px) {
        left: 28rem;
        width: calc(100% - 28rem);
    }

    @media screen and (max-width: 450px) {
        left: 25rem;
        width: calc(100% - 25rem);
    }

    &__content {
        width: calc(100dvw - 47.5rem);
        height: 100%;
        display: flex;
        column-gap: 2.4rem;
        background-color: $white;
        padding-right: 2.4rem;
        position: sticky;
        left: 246px;
    }

    &__picture {
        width: calc(65% - 1.2rem);
        height: 100%;
        position: relative;
        z-index: 1;
        overflow: hidden;
        background-color: $gray;

        @media screen and (max-height: 500px) {
            width: calc(45% - 1.2rem);
        }

        @media screen and (max-height: 400px) {
            width: calc(40% - 1.2rem);
        }

        & > div {
            position: relative;
            width: 100%;
            height: 100%;
        }

        & > div > div {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 2% 0;
        }
    }

    &__data {
        width: calc(35% - 1.2rem);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 1.2rem;

        @media screen and (max-height: 500px) {
            width: calc(55% - 1.2rem);
        }

        @media screen and (max-height: 400px) {
            width: calc(60% - 1.2rem);
        }
    }

    &__logo {
        display: block;
        width: 8rem;
        height: 8rem;
        position: absolute;
        z-index: 1;
        bottom: 2.4rem;
        right: 2.4rem;
    }

    &__bg {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
    }

    &__img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
        filter: drop-shadow(0 11.9rem 10.3rem rgba(#615a4d26, 0.15));
    }

    &__topData {
        width: 100%;
        padding-top: 2.5rem;
        border-top: 1px solid $gold1;

        @media screen and (max-width: 768px) {
            padding-top: 1.2rem;
        }

        @media screen and (max-height: 400px) {
            padding-top: 1.2rem;
        }
    }

    &__bottomData {
        width: 100%;
        padding: 2.4rem;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
        background-color: $gray1;

        @media screen and (max-height: 550px) {
            padding: 1.2rem;
        }
    }

    &__lineData {
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        column-gap: 0.9rem;
    }

    &__textData {
        color: $black;
        font-size: 1.8rem;
        line-height: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.06em;
        text-align: right;

        &_label {
            color: rgba($color: $black, $alpha: 0.5);
            text-align: left;
        }

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }

        @media screen and (max-height: 500px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }

        @media screen and (max-height: 550px) {
            font-size: 1.2rem;
            line-height: 1.2rem;
        }
    }

    h4 {
        color: $black;
        font-size: 2.4rem;
        line-height: 2.8rem;
        font-weight: 300;
        text-transform: uppercase;

        @media screen and (max-width: 768px) {
            font-size: 2rem;
            line-height: 2.3rem;
        }

        @media screen and (max-height: 500px) {
            font-size: 2rem;
            line-height: 2.3rem;
        }

        @media screen and (max-height: 400px) {
            font-size: 1.4rem;
            line-height: 1.6rem;
        }
    }
}

@keyframes showLi {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes showProduct {
    0% {
        transform: rotateY(-90deg) scale(0.5);
        opacity: 0;
    }
    70% {
        transform: rotateY(-10deg) scale(0.7);
    }
    100% {
        transform: rotateY(0) scale(1);
        opacity: 1;
    }
}

@keyframes hideProduct {
    0% {
        transform: rotateY(0) scale(1);
        opacity: 1;
    }
    30% {
        transform: rotateY(-10deg) scale(0.7);
    }
    100% {
        transform: rotateY(-90deg) scale(0.5);
        opacity: 0;
    }
}

.showProduct {
    animation: showProduct 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    pointer-events: all;
}

.hideProduct {
    animation: hideProduct 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    pointer-events: none;
}

.vproducts {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 6rem;
    transition: all 0.5s;

    &.active {
        flex-direction: column-reverse;
        row-gap: 1.2rem;
    }

    &__manage {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
    }

    &__submenu {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 1.2rem;
        transition: all 0.5s;

        &.active {
            flex-direction: row;
            row-gap: 2.4rem;
            width: 100%;
            column-gap: 1.2rem;
            overflow-y: hidden;
            overflow-x: auto;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }

            & > div {
                flex-shrink: 0;

                span {
                    white-space: nowrap;
                }
            }
        }
    }

    &__clearIco {
        width: 2.4rem;
        height: 2.4rem;
        display: block;
        flex-shrink: 0;
    }

    &__clear {
        max-width: 100%;
        width: fit-content;
        display: flex;
        align-items: center;
        column-gap: 1.6rem;
        margin-bottom: 1.2rem;

        @media screen and (hover) {
            cursor: pointer;
            transition: filter 0.5s;

            &:hover {
                filter: grayscale(1) brightness(0);
            }
        }

        @media screen and (max-height: 550px) {
            margin-bottom: 0;
        }
    }

    &__liList {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;
        animation: showLi 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    }

    &__li {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 1.6rem;

        &::before {
            content: '';
            display: block;
            width: 0.5rem;
            height: 0.5rem;
            flex-shrink: 0;
            border-radius: 50%;
            background-color: rgba($color: $gold1, $alpha: 0.3);
        }
    }

    &__swiper {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
    }

    &__bottlePic {
        display: block;
        width: 100%;
        // filter: grayscale(1);
        transition: filter 0.5s;
    }

    &__bottle {
        width: 100%;
        transition: background-color 1s;
        cursor: pointer;

        &:not(:hover) {
            background-color: transparent !important;
        }

        &:hover .vproducts__bottlePic {
            filter: none;
        }

        &_show {
            animation: scaleUp 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
        }

        &_hide {
            animation: scaleDown 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
        }
    }
    h4 {
        color: $black;
        font-size: 2.4rem;
        line-height: 2.8rem;
        font-weight: 300;
        text-transform: uppercase;

        @media screen and (max-width: 768px) {
            font-size: 2rem;
            line-height: 2.3rem;
        }

        @media screen and (max-height: 500px) {
            font-size: 2rem;
            line-height: 2.3rem;
        }

        @media screen and (max-height: 400px) {
            font-size: 1.4rem;
            line-height: 1.6rem;
        }
    }
}

.vproduct {
    width: 100%;
    z-index: 1;
    transform-style: preserve-3d;
    transform-origin: 20% 50%;

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
        background-color: $white;
        animation: showProduct 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    }

    &__picture {
        width: 100%;
        position: relative;
        z-index: 1;
        overflow: hidden;
        background-color: $gray;

        & > div {
            position: relative;
            width: 100%;
            height: 100%;
        }

        & > div > div {
            position: relative;
            inset: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 2% 0;
        }

        & > div > div:nth-child(2) {
            position: absolute;
            img {
                height: 100%;
            }
        }
    }

    &__data {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 1.2rem;
    }

    &__logo {
        display: block;
        width: 8rem;
        height: 8rem;
        position: absolute;
        z-index: 1;
        bottom: 2.4rem;
        right: 2.4rem;
    }

    &__bg {
        width: 100%;
        display: block;
        object-fit: contain;
    }

    &__img {
        width: 100%;
        display: block;
        object-fit: contain;
        filter: drop-shadow(0 11.9rem 10.3rem rgba(#615a4d26, 0.15));
    }

    &__topData {
        width: 100%;
        padding-top: 2.5rem;
        border-top: 1px solid $gold1;

        @media screen and (max-width: 768px) {
            padding-top: 1.2rem;
        }
    }

    &__bottomData {
        width: 100%;
        padding: 2.4rem;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
        background-color: $gray1;

        @media screen and (max-width: 768px) {
            padding: 1.2rem;
        }
    }

    &__lineData {
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        column-gap: 0.9rem;
    }

    &__textData {
        color: $black;
        font-size: 1.8rem;
        line-height: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.06em;
        text-align: right;

        &_label {
            color: rgba($color: $black, $alpha: 0.5);
            text-align: left;
        }

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }
    }
}
