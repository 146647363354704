@import './../../../assets/variables.scss';

.cocktails {
    min-width: 100dvw;
    flex-shrink: 0;
    height: 100%;
    position: relative;

    &__content {
        display: flex;
        column-gap: 2.4rem;
        height: 100%;

        @media screen and (max-width: 990px) {
            column-gap: 1.2rem;
        }
    }

    &__compound {
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        justify-content: flex-end;
        width: 0;
        min-width: 25rem;
        flex-grow: 0.43;

        @media screen and (max-height: 450px) {
            row-gap: 0.8rem;
        }

        @media screen and (max-width: 990px) {
            flex-grow: 0;
            width: 40rem;
        }
    }

    &__table {
        width: 100%;
        padding: 2.4rem;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
        background-color: $gray1;

        @media screen and (max-width: 768px) {
            padding: 1.2rem;
        }

        @media screen and (max-height: 450px) {
            padding: 1.2rem;
            row-gap: 0.8rem;
        }
    }

    &__tableTitle {
        padding-bottom: 1.2rem;
        border-bottom: 1px solid $gold1;

        @media screen and (max-height: 450px) {
            padding-bottom: 0.8rem;
        }
    }

    &__tableList {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;

        @media screen and (max-height: 700px) {
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 1.2rem;
            width: 100%;
        }

        @media screen and (max-height: 340px) {
            row-gap: 0.8rem;
        }
    }

    &__lineData {
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        column-gap: 0.9rem;
    }

    &__textData {
        color: $black;
        font-size: 1.8rem;
        line-height: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.06em;
        text-align: right;

        &_label {
            color: rgba($color: $black, $alpha: 0.5);
            text-align: left;
        }

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }

        @media screen and (max-height: 450px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }

        @media screen and (max-height: 340px) {
            font-size: 1.2rem;
            line-height: 1.2rem;
        }
    }

    &__recipe {
        color: $black;
        font-size: 1.8rem;
        line-height: 1.8rem;
        font-weight: 400;
        text-transform: uppercase;

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }

        @media screen and (max-height: 500px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }

        @media screen and (max-height: 340px) {
            font-size: 1.2rem;
            line-height: 1.2rem;
        }
    }

    &__pic {
        height: 100%;
        min-height: 0;
        min-width: 0;
        display: block;
        width: calc((100dvh - 19.2rem) * 0.82);
        object-fit: cover;
        flex-grow: 0;

        // @media screen and (max-height: 925px) and (max-width: 1450px) {
        //     width: calc((100dvh - 14rem)*0.78);
        // }

        @media screen and (max-height: 650px) {
            width: calc((100dvh - 10rem) * 0.82);
        }

        @media screen and (max-width: 768px) {
            width: calc((100dvh - 10rem) * 0.82);
        }
    }

    &__right {
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
        width: 0;
        flex-grow: 0.57;
        min-width: 40rem;

        @media screen and (max-width: 990px) {
            flex-grow: 1;
        }

        @media screen and (max-width: 840px) {
            flex-grow: 0;
        }
    }

    &__description {
        margin-top: auto;
    }

    &__submenu {
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
        margin-top: 6rem;

        @media screen and (max-height: 500px) {
            row-gap: 0.8rem;
        }

        span {
            white-space: unset;
        }
    }
}

.vcocktails {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2.8rem;

    &__manage {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
    }

    &__submenu {
        width: 100%;
        display: flex;
        flex-direction: row;
        column-gap: 1.2rem;
        overflow-y: hidden;
        overflow-x: auto;

        & > div {
            column-gap: 1.2rem;
        }

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        & > div > div {
            flex-shrink: 0;

            span {
                white-space: nowrap;
            }
        }
    }

    &__pic {
        width: 100%;
        display: block;

        img {
            width: 100%;
            display: block;
        }

        @media screen and (max-width: 768px) {
            width: calc(50% - 0.6rem);
        }

        @media screen and (max-width: 550px) {
            width: 100%;
        }
    }

    &__data {
        width: 100%;
        display: flex;
        flex-direction: row;
        column-gap: 2.4rem;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            row-gap: 2.4rem;
        }
    }

    &__common {
        width: calc(50% - 1.2rem);
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;

        @media screen and (max-width: 768px) {
            width: 100%;
            flex-direction: row;
            column-gap: 1.2rem;
        }

        @media screen and (max-width: 550px) {
            flex-direction: column;
            row-gap: 1.6rem;
        }
    }

    &__descr {
        width: 100%;

        @media screen and (max-width: 768px) {
            width: calc(50% - 0.6rem);
        }

        @media screen and (max-width: 550px) {
            width: 100%;
        }
    }

    &__params {
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
        width: calc(50% - 1.2rem);

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        @media screen and (max-width: 550px) {
            row-gap: 0.4rem;
        }
    }

    &__table {
        width: 100%;
        padding: 2.4rem;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
        background-color: $gray1;

        @media screen and (max-width: 768px) {
            padding: 1.2rem;
        }
    }

    &__tableTitle {
        width: 100%;
        padding-bottom: 1.2rem;
        border-bottom: 1px solid $gold1;
    }

    &__tableList {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
    }

    &__lineData {
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        column-gap: 0.9rem;
    }

    &__textData {
        color: $black;
        font-size: 1.8rem;
        line-height: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.06em;
        text-align: right;

        &_label {
            color: rgba($color: $black, $alpha: 0.5);
            text-align: left;
        }

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }
    }

    &__recipe {
        color: $black;
        font-size: 1.8rem;
        line-height: 1.8rem;
        font-weight: 400;
        text-transform: uppercase;

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
        }
    }

    &__container {
        position: relative;
    }
}

.anchor {
    position: absolute;
    top: -100px;
    pointer-events: none;
    left: 0;
    opacity: 0;
    width: 100%;
    background-color: red;
    height: 10px;
}

.cocktails_slider {
    &__swiper {
        position: static;
    }
    &__wrapper {
        position: static;
    }
    &__slide {
        position: static;
    }
}
